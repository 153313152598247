import React from "react"
import { window, location } from "browser-monads"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"

import MailImage from "../assets/img/mail.png"
import Layout from "../components/layout"
import Progress from "../components/progress"

import CenteredContainer from "../components/CenteredContainer"
import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
  getServiceQueryParam,
} from "../utils"
import FancyInput, { FancyCardHeader } from "../components/FancyInput"

const getErrorMessage = errStatus => {
  switch (errStatus) {
    case 403:
      return (
        <>
          User is already verified. Click{" "}
          <Link to={`/login/${getRedirectPathFromUrl()}`}>here</Link> to login.
          <br />
          Forgot password? Click{" "}
          <Link to={`/forgotPassword/${getRedirectPathFromUrl()}`}>
            here
          </Link>{" "}
          to reset your password.
        </>
      )
    case 500:
      return "Cannot access service due to server error. Please try again later."
    default:
      return "Failed to send verification email"
  }
}

const { captchaSiteKey } = window

class ResendVerify extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: getQueryParam("email") || "",
      errorStatus: "",
      grecaptchaValue: "",
      resendStatus: API_CALL_STATUSES.IDLE,
      showCaptchaMessage: false,
    }

    this.grecaptchaRef = React.createRef()
  }

  callVerify = e => {
    e.preventDefault()
    const { grecaptchaValue, email } = this.state
    if (!grecaptchaValue) {
      this.setState({
        showCaptchaMessage: true,
      })
      return null
    }

    this.setState({
      showCaptchaMessage: false,
      resendStatus: API_CALL_STATUSES.PROGRESS,
    })
    ajax({
      path: `user/verify?resendEmail=${encodeURIComponent(email || "")}`,
      headers: { path: "/", service: getServiceQueryParam() },
      type: "PUT",
      data: {
        email,
        captcha: grecaptchaValue,
        "g-recaptcha-response": grecaptchaValue,
      },
      success: () => {
        this.setState({
          resendStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: err => {
        this.grecaptchaRef.current.reset()
        this.setState({
          grecaptchaValue: "",
          resendStatus: API_CALL_STATUSES.ERROR,
          errorStatus: err && err.status ? err.status : "",
        })
      },
    })
  }
  resendEmailFlow = e => {
    location.reload()
  }
  onCaptchaChange = grecaptchaValue => {
    this.setState({
      grecaptchaValue,
      showCaptchaMessage: !grecaptchaValue,
    })
  }

  updateEmail = e => {
    this.setState({
      email: e.target.value,
    })
  }

  render() {
    const { resendStatus, email, showCaptchaMessage, errorStatus } = this.state

    return (
      <Layout dark bgimage stickyFooter>
        {resendStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <CenteredContainer>
          {resendStatus === API_CALL_STATUSES.SUCCESS ? (
            <div
              id="success"
              className="text-blueGray-400 text-center mb-3 font-bold"
            >
              <FancyCardHeader title="Verification Mail Sent">
                <img src={MailImage} alt="" />
                <small className="text-left">
                  Please check your email to verify your account.
                  <i>Please also check your spam folder</i> or{" "}
                  <Link
                    to={`/resendVerify/${getRedirectPathFromUrl()}&email=${encodeURIComponent(
                      email
                    )}`}
                    onClick={this.resendEmailFlow}
                  >
                    Resend Email
                  </Link>
                </small>
              </FancyCardHeader>
            </div>
          ) : (
            <form noValidate id="resetForm" onSubmit={this.callVerify}>
              <FancyCardHeader title="Resend Verification Mail">
                <small className="text-left">
                  Enter your email address below to send a verification link
                </small>
              </FancyCardHeader>

              <div className="inputWrapper">
                <FancyInput
                  data-id="email"
                  type="email"
                  id="email"
                  placeholder="Email address"
                  value={email}
                  onChange={this.updateEmail}
                  required
                />
              </div>
              {resendStatus === API_CALL_STATUSES.ERROR && (
                <p id="error">{getErrorMessage(errorStatus)}</p>
              )}
              <div className="flex content-center items-center justify-center py-6 px-6">
                <ReCAPTCHA
                  ref={this.grecaptchaRef}
                  sitekey={captchaSiteKey}
                  onChange={this.onCaptchaChange}
                />
              </div>
              {showCaptchaMessage && (
                <span className="validation" id="captchaValidation">
                  Captcha verification is mandatory. Please select the checkbox
                  above.
                </span>
              )}
              <button id="continue" className="redBtn" type="submit">
                CONTINUE
              </button>
            </form>
          )}
        </CenteredContainer>
      </Layout>
    )
  }
}

export default ResendVerify
